@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import 'scss/style';
@import 'scss/style-menu-left';

@import 'scss/tailwind-primeng-rewrite';
@import 'scss/tailwind-primeng-autocomplete';
@import 'scss/tailwind-primeng-button';
@import 'scss/tailwind-primeng-calendar';
@import 'scss/tailwind-quill-core';

@import 'scss/theme/theme';

// Flowbite
@plugin "flowbite/plugin";
@import 'flowbite/src/themes/default';

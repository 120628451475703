.menu-left {
  transition: width 0.2s;
  @apply h-full;
  @apply pr-1;
  @apply overflow-y-auto;
  z-index: 2;
  /* @apply bg-[#222259]; */
}
.menu-left-expanded {
  // @apply hidden;
}
.menu-left:hover .menu-left-expanded {
  display: block !important;
}
